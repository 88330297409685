// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  border-color: rgb(232, 232, 232);
  border-style: solid;
  border-width: 0px;
  font-size: 100%;
  color: currentcolor;
  vertical-align: baseline;
  // font-family: "Comfortaa", sans-serif !important;
  font-family: "Lato",sans-serif !important;
}

body {
  // background: radial-gradient(50% 50% at 50% 50%, rgba(82, 152, 255, 0.063) 0%, rgba(255, 255, 255, 0) 100%), rgb(247, 247, 247);
  background-image: url('https://edx-pre-sale.s3.ap-south-1.amazonaws.com/Presale.png');
  background-size: cover; /* To make the image cover the entire element */
  background-position: center; /* To center the image */
  background-repeat: no-repeat;
  min-height: 100vh;
}

.min-safe {
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem;
  gap: 1rem;
}

#header {
  height: 3rem;
}

.header-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  height: 3rem;
}

.header-main img {
  height: 3rem;
}

.connect-wallet {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.connect-wallet button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition-property: all;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  background: rgb(56, 136, 255);
  color: rgb(255, 255, 255);
  border-color: transparent;
  font-size: 1rem;
  line-height: 1.25rem;
  height: 3rem;
  padding: 0px 1rem;
  border-radius: 9999px;
  width: 11.25rem;
}

.connect-wallet button:hover {
  transform: translateY(-1px);
  background: rgb(107, 166, 255);
}

.wallet-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.main {
  max-width: 48rem;
  width: 100%;
  flex-grow: 0.8;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-self: center;
}

.main-content {
  flex-grow: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.register {
  flex-direction: column;
  flex-grow: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: center;
  max-width: 48rem;
}

.timer {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  align-self: center;
  max-width: 48rem;
}

.main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.main-text h1 {
  text-align: center;
  font-weight: 700;
  // background-repeat: no-repeat;
  // background-size: 110%;
  background-clip: text;
  // color: transparent;
  margin: 0px;
  // background-image: linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.26%);
  font-size: 1.875rem;
  color:white;
}

.main-sub-title {
  max-width: calc(35rem);
  line-height: 150%;
  text-align: center;
  margin-bottom: 0.75rem;
}

.main-sub-title-text {
  color: rgb(155, 155, 166);
  font-size: 1.125rem;
  // font-weight: 500;
  line-height: 1.5rem;
}

.main-sub-title-text-white {
  color: rgb(255, 255, 255);
  font-size: 1.125rem;
  // font-weight: 500;
  line-height: 1.5rem;
}


.MuiInputBase-root {
  box-shadow: rgb(245, 245, 245) 0px 2px 12px;
  height: 5rem;
  border: 1px solid rgb(232, 232, 232);
  margin-bottom: 0;
  text-overflow: ellipsis;
  padding: 0.5rem;
  background-color: white;
  font-size: 1.2rem !important;
}

.MuiOutlinedInput-root {
  border-radius: 15px !important;
}

.invalid-text {
  display: flex;
  border-radius: 10px;
  max-width: 100%;
  height: 3.5rem;
  border: 1px solid #F8E6E7;
  color: black;
  padding: 0px 3.5rem 0px 1.5rem;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
  font-size: 3.2vw;
  background: #F8E6E7;
}

.commit-button {
  width: 100%;
  position: relative;
  margin-top: 25px;
}

.commit-button button {
  display: flex;
  box-shadow: rgb(245, 245, 245) 0px 2px 12px;
  border-radius: 10px;
  width: 100%;
  height: 3.5rem;
  border: 1px solid rgb(232, 232, 232);
  color: rgb(245, 245, 245);
  padding: 0px 3.5rem 0px 1.5rem;
  font-size: 1.35rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgb(56, 136, 255);
}

.message-info {
  margin-top: -5px;
  max-width: 82%;
}

.profile-section {
  align-self: center;
  max-width: 48rem;
  width: 100%;
}

.profile-option {
  gap: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.2rem;
  justify-content: flex-start;
  overflow: auto;
  margin-bottom: 1.2rem;
}

.profile-tab,
.records-tab,
.ownership-tab,
.subnames-tab,
.more-tab {
  display: block;
  outline: none;
  border: none;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  background: none;
  font-size: 1.25rem;
  transition: all 0.15s ease-in-out 0s;
  cursor: pointer;
  color: #9b9ba6;
}

.profile-tab:hover,
.records-tab:hover,
.ownership-tab:hover,
.subnames-tab:hover,
.more-tab:hover {
  color: rgb(255, 255, 255);
}

.profile-tab.active,
.records-tab.active,
.ownership-tab.active,
.subnames-tab.active,
.more-tab.active {
  color: #3888ff;
}

.profile-button {
  border: 1px solid #e8e8e8 !important;
  width: fit-content !important;
}

.unsupported-page {}

@media (min-width: 640px) {
  .container {
    gap: 1.5rem;
  }

  .main-text h1 {
    font-size: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .header-main {
    gap: 1.5rem;
  }

  .invalid-text {
    font-size: 1vw;
  }
}

.learn-more-button {
  display: flex;
  justify-content: center; 
  align-items: center;
  padding-top: 15px;
  // width: 50%;
  // height: 100%;
}

.learn-more-button button {
  background-color: #007BFF;
  color: #fff;
  // border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 30%;
}

.learn-more-button button:hover {
  background-color: #0056b3;
}

.pb-15 {
  padding-bottom: 15px;
}